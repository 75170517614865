import React, { useEffect, useState } from "react";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import EditDriverModel from "./edit_driver_model";
import DeleteModel from "./View_document_model";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
const Driver_details_table = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const handleViewDocument = (driver) => {
    setModalContent(driver);
    setDocumentOpen(true);
  };
  const handleEditDriver = (driver) => {
    setModalContent(driver);
    setEditOpen(true);
  };
  const closeModal = () => {
    setEditOpen(false);
    setDocumentOpen(false);
    setModalContent(null);
  };
  const searchfunction = async (query) => {
    try {
      const response = await axios.get(`${backendUrl}/driver/get-details`, {
        params: { search: query },
      });
      setDriverData(response.data);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };

  const exportToCSV = () => {
    const csvData = driverData.map(() => ({
      Driver_id: driverData.driver_id,
      Employee_fullname: driverData.employee_fullname,
      Employee_number: driverData.employee_number,
      Employee_contact_no: driverData.employee_contact_no,
      Employee_email: driverData.employee_email,
      License_number: driverData.license_number,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "driver_data.csv");
  };

  const getDriverData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/driver/get-all-drivers`);
      setDriverData(response.data.drivers);
      console.log(response.data.drivers);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };
  useEffect(() => {
    getDriverData();
  }, []);

  useEffect(() => {
    searchfunction(searchInput);
  }, [searchInput]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            {/* <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div> */}

            {/* <div className="relative"> */}
            {/* <button 
        className="p-2 border border-black rounded-[12px]" 
        // onClick={toggleDropdown}
      >
        <div className="flex items-center gap-3">
          Filter <RiMenu5Fill />
        </div>
      </button> */}
            {/* <select
                name="vehicle_type"
                className="p-2 border border-black rounded-[12px]"
                // value={vehicleDetails.vehicle_type}
                // onChange={handleInputChange}
              >
                <option value="">Select Vehicle Type</option>
                <option value="ACTIVE">CAR</option>
                <option value="INACTIVE">VAN</option>
                <option value="INACTIVE">SUV</option>
              </select> */}
            {/* </div> */}
          </div>
          {/* <div className="flex items-center gap-2 text-black">
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="w-5 h-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div>
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex items-center gap-3">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div> */}
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Driver ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Employee Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Phone Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Email Address
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              License Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              License Document
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {driverData.length > 0 ? (
            driverData.map((driver) => (
              <tr key={driver.driver_id}>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.driver_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.employee_fullname}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.employee_number}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.employee_contact_no}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.employee_email}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {driver.license_number}
                </td>

                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {""}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEditDriver(driver)}
                  >
                    <AiTwotoneEdit />
                  </button>
                  <button
                    className="ml-2 text-red-500 hover:text-red-700"
                    onClick={() => handleViewDocument(driver)}
                  >
                    <MdDeleteOutline />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No data found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {editOpen && modalContent && (
        <EditDriverModel
          isOpen={editOpen}
          onClose={closeModal}
          driver={modalContent}
        />
      )}
      {documentOpen && modalContent && (
        <DeleteModel
          isOpen={documentOpen}
          onClose={closeModal}
          driver={modalContent}
        />
      )}
    </div>
  );
};
export default Driver_details_table;
