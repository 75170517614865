import React, { useState, useEffect } from "react";
// import Navbar from "../navbar/navbar";
import moment from "moment";
import { format } from "date-fns";
import { CiCirclePlus } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import FileUpload from "../driver_details/upload_files";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this import for DatePicker styling

// import Time_Management_Table from "./time_management_table";
const AddVehiclePopup = ({ togglePopup }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [startDate, setStartDate] = useState(); // Define startDate state here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [endDate, setEndDate] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [vehicleNumbers, setVehicleNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({
    vehicle_id: "",
    vehicle_name: "",
    vehicle_model: "",
    vehicle_brand: "",
    vehicle_type: "",
    vehicle_year: "",
    vehicle_registration_no: "",
    vehicle_mileage: "",
    vehicle_status: "",
    vehicle_insurance: "",
    vehicle_fuel_type: "",
    vehicle_no_plate_no: "",
    vehicle_last_maintenance_date: "",
    vehicle_next_maintenance_date: "",
    vehicle_imei: "",
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // File Sate Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Auto Generating Vehicle ID
  const fetchLastVehicleID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/vehicle/get-last-vehicle-id`
      );
      const { lastVehicleId } = response.data;
      const newVehicleId = `V${(parseInt(lastVehicleId.slice(1)) + 1)
        .toString()
        .padStart(3, "0")}`;
      setVehicleDetails((prevDetails) => ({
        ...prevDetails,
        vehicle_id: newVehicleId,
      }));

      console.log(newVehicleId);
    } catch (error) {
      console.error("Error fetching last Vehicle ID:", error);
      // setErrorMessage("Failed to fetch last driver ID");
    }
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // File uploading part
  const handleFileUpload = async (vehicle_id) => {
    // console.log(uploadedFiles);

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("vehicle_id", vehicle_id);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await axios.post(`${backendUrl}/vehicle/uploadVRegistrations`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadedFiles([]);
    } catch (error) {
      toast.error("Error uploading files");
    }
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(vehicleDetails);
    try {
      let response = await axios.post(
        `${backendUrl}/vehicle/`,
        vehicleDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        // Ensure vehicle_id is properly set in vehicleDetails
        if (vehicleDetails.vehicle_id) {
          await handleFileUpload(vehicleDetails.vehicle_id);
        } else {
          toast.error("Vehicle Document uploading Failed");
        }
        toast.success("Vehicle details saved successfully.");
        setTimeout(() => {}, 2000);
      }
    } catch (error) {
      console.log(error);

      // Check if the error response exists and handle 400 status specifically
      if (error.response) {
        // If the status code is 400, handle it appropriately
        if (error.response.status === 400) {
          toast.error(error.response.data[0]?.msg || error.response.data.error);
        } else {
          toast.error("Failed to submit Vehicle data");
        }
        // Log and show any error message from the response
        console.log(error.response);
      } else {
        // If no response, it's likely a network or client error
        toast.error("Failed to submit Vehicle data. Please try again.");
      }
    }

    setIsSaveConfirmationOpen(false);
  };

  // use State Reset
  const handleResetForm = () => {
    setVehicleDetails({
      vehicle_id: "",
      vehicle_name: "",
      vehicle_model: "",
      vehicle_brand: "",
      vehicle_type: "",
      vehicle_year: "",
      vehicle_registration_no: "",
      vehicle_mileage: "",
      vehicle_status: "",
      vehicle_insurance: "",
      vehicle_fuel_type: "",
      vehicle_no_plate_no: "",
      vehicle_last_maintenance_date: "",
      vehicle_next_maintenance_date: "",
      vehicle_imei: "",
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const handleDateChange = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: formattedDate,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchLastVehicleID();
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Vehicle</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <form>
          <div className="items-center gap-8 mt-5 ">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">
              {/* Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID</label>
                <input
                  type="text"
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_id}
                  readOnly
                />
              </div>

              {/* Vehicle Name */}
              <div>
                <label className="block text-gray-700">Vehicle Name</label>
                <input
                  type="text"
                  name="vehicle_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Vehicle Number Plate */}
              <div>
                <label className="block text-gray-700">
                  Vehicle Number Plate
                </label>
                <input
                  type="text"
                  name="vehicle_no_plate_no"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_no_plate_no}
                  onChange={handleInputChange}
                />
              </div>
              {/* Model Number */}
              <div>
                <label className="block text-gray-700">Model Number</label>
                <input
                  type="text"
                  name="model_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.model_number}
                  onChange={handleInputChange}
                />
              </div>

              {/* vehicle_type */}
              <div>
                <label className="block text-gray-700">Vehicle Type</label>
                <select
                  name="vehicle_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="ACTIVE">CAR</option>
                  <option value="INACTIVE">VAN</option>
                  <option value="INACTIVE">SUV</option>
                </select>
              </div>

              {/* Gasoline */}
              {/* <div>
                <label className="block text-gray-700">Gasoline</label>
                <input
                  type="FLOAT"
                  name="vehicle_mileage"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_mileage}
                  onChange={handleInputChange}
                />
              </div> */}
              {/* Make  */}
              <div>
                <label className="block text-gray-700">Make</label>
                <input
                  type="text"
                  name="vehicle_brand"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_brand}
                  onChange={handleInputChange}
                />
              </div>

              {/* vehicle_fuel_type */}
              <div>
                <label className="block text-gray-700">Fuel Type</label>
                <select
                  name="vehicle_fuel_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_fuel_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Fuel Type</option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Electric">Electric</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>

              {/* Year */}
              <div>
                <label className="block text-gray-700">Year</label>
                <input
                  type="text"
                  name="vehicle_year"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_year}
                  onChange={handleInputChange}
                />
              </div>

              {/* Registration Number  */}
              <div>
                <label className="block text-gray-700">
                  Registration Number
                </label>
                <input
                  type="text"
                  name="vehicle_registration_no"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_registration_no}
                  onChange={handleInputChange}
                />
              </div>

              {/* Milage  */}
              <div>
                <label className="block text-gray-700">Milage</label>
                <input
                  type="text"
                  name="vehicle_mileage"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_mileage}
                  onChange={handleInputChange}
                />
              </div>
              {/*Status */}
              <div>
                <label className="block text-gray-700">Status</label>
                <select
                  name="vehicle_status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_status}
                  onChange={handleInputChange}
                >
                  <option value="">Select Vehicle Status</option>
                  <option value="OPERATIONAL">OPERATIONAL</option>
                  <option value="UNDER MAINTENANCE">UNDER MAINTENANCE</option>
                  <option value="CAR MALFUNCTION">CAR MALFUNCTION</option>
                </select>
              </div>

              {/* Insurance Expiring Date  */}
              <div>
                <label className="block text-gray-700">
                  Insurance Expiring Date
                </label>
                <DatePicker
                  selected={vehicleDetails.vehicle_insurance}
                  onChange={(date) =>
                    handleDateChange(date, "vehicle_insurance")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              {/* Service */}
              {/* <div>
                <label className="block text-gray-700">Date Of Service</label>
                <DatePicker
                  selected={vehicleDetails.vehi}
                  onChange={(date) => handleDateChange(date, "vehicle_year")}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy/MM/dd"
                />
              </div> */}
              {/*  Maintenance Date */}
              <div>
                <label className="block text-gray-700">
                  Last Maintenance Date
                </label>
                <DatePicker
                  selected={vehicleDetails.vehicle_last_maintenance_date}
                  onChange={(date) =>
                    handleDateChange(date, "vehicle_last_maintenance_date")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              {/*  Next Date */}
              <div>
                <label className="block text-gray-700">
                  Next Maintenance Date
                </label>
                <DatePicker
                  selected={vehicleDetails.vehicle_next_maintenance_date}
                  onChange={(date) =>
                    handleDateChange(date, "vehicle_next_maintenance_date")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div>
                <label className="block text-gray-700">MEIE Number</label>
                <input
                  type="text"
                  name="vehicle_imei"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_imei}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button>

          {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default AddVehiclePopup;
