/** @format */

import React from "react";
import { MdDashboard } from "react-icons/md";
import { FaCarAlt } from "react-icons/fa";
import { FaRoad } from "react-icons/fa";
import { LuFuel } from "react-icons/lu";
import { GrHostMaintenance } from "react-icons/gr";
import { MdInventory } from "react-icons/md";
import { GrDocumentPerformance } from "react-icons/gr";
import { MdApproval } from "react-icons/md";
import { MdIncompleteCircle } from "react-icons/md";
import { AiFillApi } from "react-icons/ai";

import { GiGate } from "react-icons/gi";

import { MdCoffeeMaker } from "react-icons/md";

import { GrCompliance } from "react-icons/gr";

const sidebarData = [
  {
    _id: "5000",
    name: "Vehicle Information Management",
    icon: <FaCarAlt />,
    requiredPermissionsformainfeatures: ["5000"],
    subModules: [
      {
        _id: "5000.1",
        name: "Vehicle Details",
        url: "/vehicle-details",
        requiredPermissionsforsubfeatures: ["500"],
      },
      {
        _id: "1.2",
        name: "Vehicle Part Details",
        url: "/vehicle-part-details",
        requiredPermissionsforsubfeatures: ["101"],
      },
      {
        _id: "5000.3",
        name: "Driver Details",
        url: "/driver-details",
        requiredPermissionsforsubfeatures: ["520"],
      },
    ],
  },
  {
    _id: "5012",
    name: "Current Vehicle Dispatches",
    icon: <FaCarAlt />,
    requiredPermissionsformainfeatures: ["5012"],
    subModules: [
      {
        _id: "5011.1",
        name: "Current Vehicle Dispatches",
        url: "/current_vehicle_dispatches",
        requiredPermissionsforsubfeatures: ["860"],
      },
    ],
  },

  {
    _id: "5001",
    name: "Route Planning and Optimization",
    icon: <FaRoad />,
    requiredPermissionsformainfeatures: ["5001"],
    subModules: [
      {
        _id: "5001.1",
        name: "Manager Transportation Schedules",
        url: "/manager-transportation-schedules",
        requiredPermissionsforsubfeatures: ["550"],
      },

      {
        _id: "5001.2",
        name: "Create Gate Pass Requests ",
        url: "/gate_pass_requests ",
        requiredPermissionsforsubfeatures: ["560"],
      },
      {
        _id: "5001.3",
        name: "Gate Pass Requests - Approvals table",
        url: "/gate_pass_requests_approvals",
        requiredPermissionsforsubfeatures: ["570"],
      },
    ],
  },

  {
    _id: "5002",
    name: "Fuel Management",
    icon: <LuFuel />,
    requiredPermissionsformainfeatures: ["5002"],
    subModules: [
      {
        _id: "5002.1",
        name: "Fuel Consumption Data",
        url: "/fuel-consumption-data",
        requiredPermissionsforsubfeatures: ["580"],
      },
      {
        _id: "5002.2",
        name: "Fuel Cost Update",
        url: "/fuel-cost-update",
        requiredPermissionsforsubfeatures: ["590"],
      },

      {
        _id: "5002.3",
        name: "Generate Monthly Fuel Reports",
        url: "/generate-monthly-fuel-reports",
        requiredPermissionsforsubfeatures: ["600"],
      },
    ],
  },

  {
    _id: "5003",
    name: "Maintenance & Repairs",
    icon: <GrHostMaintenance />,
    requiredPermissionsformainfeatures: ["5003"],
    subModules: [
      {
        _id: "5003.1",
        name: "Upload Request Letter Form/ Approval",
        url: "/upload-request-letter-form",
        requiredPermissionsforsubfeatures: ["640"],
      },
      {
        _id: "5003.2",
        name: "Maintenance Records",
        url: "/maintenance-records",
        requiredPermissionsforsubfeatures: ["650"],
      },

      {
        _id: "5003.3",
        name: "Breakdown Records",
        url: "/breakdown-records",
        requiredPermissionsforsubfeatures: ["660"],
      },
    ],
  },

  {
    _id: "5004",
    name: "Inventory Management",
    icon: <MdInventory />,

    requiredPermissionsformainfeatures: ["5004"],
    subModules: [
      {
        _id: "5004.1",
        name: "Vehicle Count Dashboard",
        url: "/vehicle-count-dashboard",
        requiredPermissionsforsubfeatures: ["690"],
      },
      {
        _id: "5004.2",
        name: "Fuel Consumption Dashboard",
        url: "/fuel-consumption-dashboard",
        requiredPermissionsforsubfeatures: ["700"],
      },

      {
        _id: "5004.3",
        name: "Location Data Dashboard",
        url: "/location-data-dashboard",
        requiredPermissionsforsubfeatures: ["710"],
      },
    ],
  },

  {
    _id: "5005",
    name: "Driver Performance Monitoring",
    icon: <GrDocumentPerformance />,
    requiredPermissionsformainfeatures: ["5005"],
    subModules: [
      {
        _id: "5005.1",
        name: "Monthly Driver Performance",
        url: "/Monthly-Driver-Performance",
        requiredPermissionsforsubfeatures: ["720"],
      },
    ],
  },

  {
    _id: "5006",
    name: "Compliance & Reporting",
    icon: <GrCompliance />,
    requiredPermissionsformainfeatures: ["5006"],
    subModules: [
      {
        _id: "5006.1",
        name: "Vehicles Exceeding 5000km Table",
        url: "/vehicles-exceeding-5000km-table",
        requiredPermissionsforsubfeatures: ["730"],
      },
    ],
  },

  {
    _id: "5007",
    name: "Approval Process 01",
    icon: <MdApproval />,
    requiredPermissionsformainfeatures: ["5007"],
    subModules: [
      {
        _id: "5007.1",
        name: "Approval Process > Approve 01",
        url: "/Approval-process-01",
        requiredPermissionsforsubfeatures: ["740"],
      },
    ],
  },

  {
    _id: "5008",
    name: "Approval Process 02",
    icon: <MdCoffeeMaker />,
    requiredPermissionsformainfeatures: ["5008"],
    subModules: [
      {
        _id: "5008.1",
        name: "Approval Process Approve 02",
        url: "/approval_process_02",
        requiredPermissionsforsubfeatures: ["780"],
      },
      // {
      //   _id: "5008.2",
      //   name: "Approval Process Approve 02 Documents Uploading ",
      //   url: "/approve_02_Documents_Uploading",
      //   requiredPermissionsforsubfeatures: ["790"],
      // },

      // {
      //   _id: "5008.3",
      //   name: "Approval Process Approve 02 System Editing",
      //   url: "/approve-02-System-Editing",
      //   requiredPermissionsforsubfeatures: ["800"],
      // },

      // {
      //   _id: "5008.4",
      //   name: "Approval Process Approve 02 Gate Pass",
      //   url: "/approve-02-gate-pass",
      //   requiredPermissionsforsubfeatures: ["810"],
      // },
    ],
  },

  {
    _id: "5009",
    name: "Approval Process 03",
    icon: <MdIncompleteCircle />,

    requiredPermissionsformainfeatures: ["5009"],
    subModules: [
      {
        _id: "5009.1",
        name: "Approval Process Approve 03",
        url: "/approval-process-03",
        requiredPermissionsforsubfeatures: ["820"],
      },
      // {
      //   _id: "5009.2",
      //   name: "Approval Process Approve 03 Gate Pass",
      //   url: "/approve-03-Gate-Pass",
      //   requiredPermissionsforsubfeatures: ["830"],
      // },
    ],
  },

  {
    _id: "5010",
    name: "Permission",
    icon: <AiFillApi />,
    requiredPermissionsformainfeatures: ["5010"],
    subModules: [
      {
        _id: "5010.1",
        name: "permission",
        url: "/permission",
        requiredPermissionsforsubfeatures: ["840"],
      },
    ],
  },

  {
    _id: "5011",
    name: "Vehicles Location",
    icon: <GiGate />,
    requiredPermissionsformainfeatures: ["5011"],
    subModules: [
      {
        _id: "5011.1",
        name: "track-my-car ",
        url: "https://gps.trackmycar.lk/index.php",
        requiredPermissionsforsubfeatures: ["850"],
      },
    ],
  },
];

export default sidebarData;

