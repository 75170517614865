/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import VehicleDetailsTable from "../../components/table/vehicle_parts_table";
import Current_vehicle_dispatches_table from "../../components/table/current_vehicle_dispatches_table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";
import FuelConsumptionPopup from "../../components/popup/fuel_consumption_popup";
import Navbar from "../../components/navbar/navbar";

const Current_vehicle_dispatches = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6 gap-8">
          <div>
            <p className="text-[30px] font-semibold gap-2">
              Current vehicle dispatches Data
            </p>
            <div className="flex align-left items-center">
              <p className="text-[15px] font-semibold text-black gap-2 mr-5">
                Vehicle fleet management system
              </p>
              <p className="text-[15px] font-semibold text-black gap-2 mr-5">
                Current vehicle dispatches
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Current vehicle dispatches Data
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <Current_vehicle_dispatches_table />
      </div>

      {/* Add Fuel Consumption Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <FuelConsumptionPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Current_vehicle_dispatches;
