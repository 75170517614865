/** @format */

import React, { useEffect, useState } from "react";

import { IoIosCloseCircleOutline } from "react-icons/io";

function Approve_gate_pass_popup({ togglePopup, itemData }) {
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Approve Gate Pass Request</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Gate Pass ID* */}
              <div className="relative">
                <label className="block text-gray-700">Gate Pass ID*</label>
                <input
                  type="text"
                  name="employeeNumber"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.schedule_id}
                  disabled
                />
              </div>
              {/* Applicant’s Name */}
              <div>
                <label className="block text-gray-700">Applicant’s Name*</label>
                <input
                  type="text"
                  name="applicant_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.applicant_name}
                  disabled
                />
              </div>

              {/* Designation* */}
              <div>
                <label className="block text-gray-700">Designation*</label>
                <input
                  type="text"
                  name="designation"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.designation}
                  disabled
                />
              </div>

              {/* Department */}
              <div>
                <label className="block text-gray-700">Department*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.requested_date}
                  disabled
                />
              </div>

              {/* Requested Date* */}
              <div>
                <label className="block text-gray-700">Requested Date*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.requested_date}
                  disabled
                />
              </div>

              {/* Departure Date */}
              <div>
                <label className="block text-gray-700">Departure Date*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.departure_date}
                  disabled
                />
              </div>

              {/* Departure Time  // include time picker for this */}
              <div>
                <label className="block text-gray-700">Departure Time*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.departure_time}
                  disabled
                />
              </div>

              {/* Departure Location :- include location for this */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Departure Location*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.departure_location}
                  disabled
                />
              </div>

              {/* Arrival Date */}
              <div>
                <label className="block text-gray-700">Arrival Date*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.arrivalDate}
                  disabled
                />
              </div>

              {/* Arrival Time */}
              <div>
                <label className="block text-gray-700">Arrival Time*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.arrivalTime}
                  disabled
                />
              </div>

              {/* Estimated amount of Fuel liters */}
              <div>
                <label className="block text-gray-700">
                  Estimated amount of Fuel liters*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.estimated_amount_of_fuel}
                  disabled
                />
              </div>

              {/* Estimated cost for Fuel */}
              <div>
                <label className="block text-gray-700">
                  Estimated cost for Fuel (LKR)*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.estimated_fuel_cost}
                  disabled
                />
              </div>

              {/* Purpose of Transport */}
              <div>
                <label className="block text-gray-700">
                  Purpose of Transport*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.purpose_of_transport}
                  disabled
                />
              </div>

              {/* Estimated KM */}
              <div>
                <label className="block text-gray-700">Estimated KM*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.estimated_km}
                  disabled
                />
              </div>

              {/* Destination Location  // include location picker here*/}

              {/* Stops */}
              {itemData.daily_routes_destinations.map((stop, index) => (
                <div key={index} className="relative mb-4">
                  <label className="block text-gray-700 mb-2">
                    Stop {index + 1}
                  </label>
                  <input
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={stop.stops}
                    disabled
                  />
                </div>
              ))}
              {/* Destination Location */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Destination Location*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.destination_location}
                  disabled
                />
              </div>
            </div>
            <hr className="my-8 border-t border-gray-300 w-[80%] mx-auto" />
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>

                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.vehicle_id}
                  disabled
                />
              </div>

              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID*</label>

                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_id}
                  disabled
                />
              </div>

              {/* Driver Name */}
              <div>
                <label className="block text-gray-700">Driver Name*</label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_1_name}
                  disabled
                />
              </div>

              {/* Vehicle Number Plate */}
              <div>
                <label className="block text-gray-700">
                  Vehicle Number Plate*
                </label>

                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.vehicleNumber}
                  disabled
                />
              </div>

              {/* ID of Driver Assistant 01 */}
              <div>
                <label className="block text-gray-700">
                  ID of Driver Assistant 01
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_assistant_id_1}
                  disabled
                />
              </div>

              {/* ID of Driver Assistant 02 */}
              <div>
                <label className="block text-gray-700">
                  ID of Driver Assistant 02
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_assistant_id_2}
                  disabled
                />
              </div>

              {/* Name of Driver Assistant 01 */}
              <div>
                <label className="block text-gray-700">
                  Name of Driver Assistant 01*
                </label>
                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_assistant_1_name}
                  disabled
                />
              </div>

              {/* Name of Driver Assistant 02 */}
              <div>
                <label className="block text-gray-700">
                  Name of Driver Assistant 02*
                </label>

                <input
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={itemData.driver_assistant_2_name}
                  disabled
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Approve_gate_pass_popup;
