import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import Navbar from "../../components/navbar/navbar";
import moment from "moment";
import RequestLetterPopup from "../../components/popup/RequestLetterPopup";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { RiDownloadCloud2Line } from "react-icons/ri";
import UploadRequestLettertable from "../../components/table/upload_Request_Letter_table";

function Upload_request_letter_form() {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const currentDate = moment().format("MMMM Do YYYY");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Upload Request Letter Form
            </p>

            <p className="text-[15px] font-semibold text-[#ABDCB0]">
              Vehicle Fleet Management System / Vehicle Information Management /
              Approval
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>

        {/* <div className="mt-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4 mt-5">
              <div className="relative">
                <input
                  className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                  placeholder="Search by vehicle ID"
                  // value={searchInput}
                  // onChange={(e) => setSearchInput(e.target.value)}
                />
                <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
              </div>
              <div>
                <button className="p-2 border border-black rounded-[12px]">
                  <div className="flex items-center gap-3">
                    Filter <RiMenu5Fill />
                  </div>
                </button>
              </div>
            </div>
            <div className="flex items-center gap-2 text-black">
              <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="w-5 h-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div>

              <div>
                <input
                  type="date"
                  className="p-2 border border-black rounded-xl "
                />
              </div>
              <button
                className="p-3 border border-black rounded-[12px]"
                onClick={exportToCSV}
              >
                <div className="flex items-center gap-3">
                  <div>Export CSV</div>
                  <RiDownloadCloud2Line />
                </div>
              </button>
            </div>
          </div>
        </div> */}

        <div className="overflow-x-auto">
          <UploadRequestLettertable />
        </div>

        <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Request Letter Form
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">{/* <DriverDetailsTable /> */}</div>

      {/* Add Driver Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <RequestLetterPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Upload_request_letter_form;
