/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { CiSearch } from "react-icons/ci";

const Manager_transportation_table = () => {
  const [searchInput, setSearchInput] = useState("");
  const [managerData, setManagerData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleViewDocument = (driver) => {
    setModalContent(driver);
    setDocumentOpen(true);
  };

  const handleEditDriver = (driver) => {
    setModalContent(driver);
    setEditOpen(true);
  };

  const closeModal = () => {
    setEditOpen(false);
    setDocumentOpen(false);
    setModalContent(null);
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // const searchfunction = async (query) => {
  //   try {
  //     const response = await axios.get(`${backendUrl}/driver/get-details`, {
  //       params: { search: query },
  //     });
  //     setDriverData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  const getManagerData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/dailyRoutesSchedule/`);
      setManagerData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };

  useEffect(() => {
    getManagerData();
  }, []);

  // useEffect(() => {
  //   searchfunction(searchInput);
  // }, [searchInput]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or name"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Gate Pass ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approval One Status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approval Two Status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approval Three Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {managerData.length > 0 ? (
            managerData.map((manager) => (
              <tr key={manager.schedule_id}>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.schedule_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.Approval_1}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.Approval_2}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.Approval_3}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No drivers found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Manager_transportation_table;
