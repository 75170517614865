import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import FileUpload from "./upload_files";

const RequestLetterPopup = ({ togglePopup }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driverDetails, setDriverDetails] = useState({
    employeeNumber: "",
    driverName: "",
    phoneNumber: "",
    emailAddress: "",
    licenseNumber: "",
    driverID: "",
  });
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDriverID = async () => {
    try {
      console.log("test 1");
      const response = await fetch(
        `${backendUrl}/driver/get-all-drivers-employee-number`
      );
      const data = await response.json();
      const numbers = data.map((item) => item.employee_no);
      setEmployeeNumbers(numbers);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    getDriverID();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "employeeNumber" && value.length > 0) {
      const filtered = employeeNumbers.filter((emp) =>
        emp.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setDriverDetails((prevDetails) => ({
      ...prevDetails,
      employeeNumber: suggestion,
    }));
    setShowSuggestions(false);
    fetchDriverDetails(suggestion);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles([]);
  };

  const fetchDriverDetails = (employeeNo) => {
    fetch(
      `${backendUrl}/driver/get-driver-data-for-Employee-Number?employee_no=${employeeNo}`
    )
      .then((response) => response.json())
      .then((data) => {
        setDriverDetails((prevDetails) => ({
          ...prevDetails,
          driverName: data[0].employee_fullname,
          phoneNumber: data[0].employee_contact_no,
          emailAddress: data[0].employee_email,
        }));
      })
      .catch((error) => {
        console.error("Error fetching driver details:", error);
      });
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    console.log(driverDetails);
    console.log(uploadedFiles);

    try {
      console.log(backendUrl);
      const response = await fetch(`${backendUrl}/driver/upload-details`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: "hello",
      });
    } catch (error) {
      console.error("Error submitting employee data:", error.message);
      setErrorMessage("Error submitting employee data");
      setSuccessMessage(""); // Clear any previous success message
    }

    setIsSaveConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setDriverDetails({
      employeeNumber: "",
      driverName: "",
      phoneNumber: "",
      emailAddress: "",
      licenseNumber: "",
      driverID: "",
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };
  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Request Letter Form</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Success Alert */}
        {successMessage && (
          <div
            className="p-4 mt-4 text-green-700 bg-green-100 border-l-4 border-green-500"
            role="alert"
          >
            <p className="font-bold">Success</p>
            <p>{successMessage}</p>
          </div>
        )}

        {/* Error Alert */}
        {errorMessage && (
          <div
            className="p-4 mt-4 text-red-700 bg-red-100 border-l-4 border-red-500"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{errorMessage}</p>
          </div>
        )}

        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Employee Number */}
              <div className="relative">
                <label className="block text-gray-700">Employee Number</label>
                <input
                  type="text"
                  name="employeeNumber"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employeeNumber}
                  onChange={handleInputChange}
                />
                {showSuggestions && filteredEmployees.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg max-h-40">
                    {filteredEmployees.map((suggestion, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Vehicle ID*/}
              <div>
                <label className="block text-gray-700">Vehicle ID</label>
                <input
                  type="text"
                  name="Vehicle ID"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driverName}
                  readOnly
                />
              </div>

              {/* Date of Request */}
              <div>
                <label className="block text-gray-700">Date of Request</label>
                <input
                  type="text"
                  name="Date of Request"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.phoneNumber}
                  readOnly
                />
              </div>

              {/* Date of Approval */}
              <div>
                <label className="block text-gray-700">Date of Approval</label>
                <input
                  type="text"
                  name="Date of Approval"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.emailAddress}
                  readOnly
                />
              </div>

              {/* Description of Issue */}
              <div>
                <label className="block text-gray-700">
                  Description of Issue
                </label>
                <input
                  type="text"
                  name="Description of Issue"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.licenseNumber}
                  onChange={handleInputChange}
                />
              </div>

              {/* Request Letter */}
              <div>
                <label className="block text-gray-700">Request Letter</label>
                <input
                  type="text"
                  name="requestLetter"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driverID}
                  onChange={handleInputChange}
                />
              </div>

              {/*  Request Letter */}
              <div>
                <label className="block text-gray-700">Request Letter</label>
                <input
                  type="to approval document"
                  name="driverID"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driverID}
                  onChange={handleInputChange}
                />
              </div>

              {/* To Approval Document  */}
              <div>
                <label className="block text-gray-700">
                  To Approval Document
                </label>
                <input
                  type="to approval document"
                  name="driverID"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driverID}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button>

          {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestLetterPopup;
