import React, { useEffect, useState } from "react";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import EditManagerTransportation from "./edite_manager_trasport";
// import EditDriverModel from "./edit_driver_model";
// import ViewDocumentModel from "./view_document_model";

const Manager_transportation_table = () => {
  const [searchInput, setSearchInput] = useState("");
  const [managerData, setManagerData] = useState([]);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // const searchfunction = async (query) => {
  //   try {
  //     const response = await axios.get(`${backendUrl}/driver/get-details`, {
  //       params: { search: query },
  //     });
  //     setDriverData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  const getManagerData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/managerRoutesSchedule`);
      setManagerData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };

  useEffect(() => {
    getManagerData();
  }, []);

  // useEffect(() => {
  //   searchfunction(searchInput);
  // }, [searchInput]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const handleDeleteClick = (item) => {
    // setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const handleEditclick = (data) => {
    setIsOpen(!isOpen); // Toggle
    setModalContent(data);
  };

  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or name"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Manager ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Manager Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Driver ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Driver Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Location
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Time
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Estimated Amount Of Fuel Litres
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Estimated Cost For Fuel
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Estimated Km For The Journey
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Journey Status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {managerData.length > 0 ? (
            managerData.map((manager) => (
              <tr key={manager.manager_id}>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.manager_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.manager_name}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.driver_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.driver_name}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.vehicle_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.departure_location}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.departure_date}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.departure_time}
                </td>

                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.estimated_amount_of_fuel}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.estimated_fuel_cost}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.estimated_km}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.journey_status}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {manager.status}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEditclick(manager)}
                  >
                    <AiTwotoneEdit />
                  </button>
                  <button
                    className="ml-2 text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteClick(manager)}
                  >
                    <MdDeleteOutline />
                  </button>
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md"></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No drivers found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditManagerTransportation
              togglePopup={togglePopup}
              managerData={modalContent}
            />
          </div>
        </div>
      )}

      {/* {documentOpen && modalContent && (
        <ViewDocumentModel
          isOpen={documentOpen}
          onClose={closeModal}
          driver={modalContent}
        />
      )} */}
    </div>
  );
};

export default Manager_transportation_table;
