import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const handleDelete = async (documentId) => {
  try {
    const response = await axios.delete(`/api/v1/documents/${documentId}`); // Adjust the endpoint based on your API
    if (response.status === 200) {
      console.log("Document deleted successfully");
      // Optional: Trigger a state update or callback to refresh the document list
    } else {
      console.error("Failed to delete document");
    }
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};
export default handleDelete;
