/** @format */

import React from "react";
import { useState } from "react";
// import Navbar from "../navbar/navbar";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this import for DatePicker styling
import SearchLocationInput from "../../../components/GooglePlcasesApi";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";

const Add_Manager_Transportation_Schedules = ({ togglePopup }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const libraries = ["places"];
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 28.7041,
    lng: 77.1025,
  });

  const [managerDetails, setManagerDetails] = useState({
    manager_id: "",
    manage_name: "",
    driver_id: "",
    driver_name: "",
    vehicle_id: "",
    departure_location: "",
    departure_date: "",
    departure_time: "",
    estimated_amount_of_Fuel_litres: "",
    estimated_cost_for_Fuel: "",
    estimated_km_for_the_journey: "",
    journey_status: "",
    status: "",
  });

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(managerDetails);

    // try {
    //   const response = await axios.post(
    //     `${backendUrl}/vehicle/`,
    //     managerDetails,
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   );

    //   if (response.status === 200) {
    //     toast.success("Manager details saved successfully.");
    //   } else {
    //     toast.error("Failed to submit Manager data");
    //   }
    // } catch (error) {
    //   toast.error(error.response.data.message);
    // }
    setIsSaveConfirmationOpen(false);
  };

  // use State Reset
  const handleResetForm = () => {
    setManagerDetails({
      manager_id: "",
      manage_name: "",
      driver_id: "",
      driver_name: "",
      vehicle_id: "",
      departure_location: "",
      departure_date: "",
      departure_time: "",
      estimated_amount_of_Fuel_litres: "",
      estimated_cost_for_Fuel: "",
      estimated_km_for_the_journey: "",
      journey_status: "",
      status: "",
    });
    setIsResetConfirmationOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManagerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAhMWAvLOlSqUyR5mIayE0akgf62pwmZKY", // Replace with your Google Maps API key
    libraries,
  });

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">
            Add Manager Transportation Schedules
          </h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <form>
          <div className="items-center gap-8 mt-5 overflow-y-auto">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">
              {/* Manager ID */}
              <div>
                <label className="block text-gray-700">Manager ID</label>
                <input
                  type="text"
                  name="manager_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.manager_id}
                  onChange={handleInputChange}
                />
              </div>
              {/* Manage Name */}
              <div>
                <label className="block text-gray-700">Manage Name</label>
                <input
                  type="text"
                  name="manage_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.manage_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID</label>
                <input
                  type="text"
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.driver_id}
                  onChange={handleInputChange}
                />
              </div>
              {/*  Driver Name */}
              <div>
                <label className="block text-gray-700"> Driver Name </label>
                <input
                  type="text"
                  name="driver_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.driver_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Vehicle Id */}
              <div>
                <label className="block text-gray-700">Vehicle Id</label>
                <input
                  type="text"
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.vehicle_id}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Location  */}
              <div>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAhMWAvLOlSqUyR5mIayE0akgf62pwmZKY" // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) => {
                      console.log(place);
                    },
                  }}
                />
              </div>
              {/* Departure Date  */}
              <div>
                <label className="block text-gray-700">Departure Date</label>
                <input
                  type="text"
                  name="departure_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.departure_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Time  */}
              <div>
                <label className="block text-gray-700">Departure Time</label>
                <input
                  type="text"
                  name="departure_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.departure_time}
                  onChange={handleInputChange}
                />
              </div>

              {/* Destination Location  */}
              <div>
                <SearchLocationInput
                  setSelectedLocation={setSelectedLocation}
                />
              </div>

              {/*  Destination Date*  */}
              <div>
                <label className="block text-gray-700">Destination Date</label>
                <input
                  type="text"
                  name="departure_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.departure_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Estimated Amount Of Fuel Litres  */}
              <div>
                <label className="block text-gray-700">
                  Estimated Amount Of Fuel Litres
                </label>
                <input
                  type="text"
                  name="estimated_amount_of_Fuel_litres"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_amount_of_Fuel_litres}
                  onChange={handleInputChange}
                />
              </div>
              {/* estimated_cost_for_Fuel */}
              <div>
                <label className="block text-gray-700">
                  Estimated Cost For Fuel
                </label>
                <input
                  type="text"
                  name="estimated_cost_for_Fuel"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_cost_for_Fuel}
                  onChange={handleInputChange}
                />
              </div>
              {/* estimated_km_for_the_journey  */}
              <div>
                <label className="block text-gray-700">
                  Estimated km for the journey
                </label>
                <input
                  type="text"
                  name="estimated_km_for_the_journey"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.estimated_km_for_the_journey}
                  onChange={handleInputChange}
                />
              </div>
              {/* journey_status */}
              <div>
                <label className="block text-gray-700">Journey Status</label>
                <input
                  type="text"
                  name="journey_status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.journey_status}
                  onChange={handleInputChange}
                />
              </div>

              {/* Status */}
              <div>
                <label className="block text-gray-700">Status</label>
                <input
                  type="text"
                  name="status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={managerDetails.status}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Add_Manager_Transportation_Schedules;
