import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import EditFuelConsumptionPopup from "../popup/edit_fuel_consumption";
import EditFuelCostUpdateDataPopup from "../popup/edit_fuel_cost_update_data";
import { CiSearch } from "react-icons/ci";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { saveAs } from "file-saver";
import Papa from "papaparse";

const UploadRequestLettertable = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const currentDate = moment().format("MMMM Do YYYY");
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [approvalData, setApprovalData] = useState([]);

  const rowsPerPage = 5;
  const [isOpen, setIsOpen] = useState(false);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleDeleteClick = (item) => {
    setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getApprovalData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/maintenance/`);
      setApprovalData(response.data.records);
      console.log(response.data.records);
    } catch (error) {
      console.error("Error fetching Approval details:", error);
    }
  };

  const searchfunction = async (query) => {
    console.log(searchInput);
    try {
      const response = await fetch(
        `${backendUrl}/maintenance?search=${encodeURIComponent(searchInput)}`
      );
      console.log(response);
      // setSearchInput(response.data);
    } catch (error) {
      console.error("Error fetching Approval details:", error);
    }
  };

  useEffect(() => {
    getApprovalData();
  }, []);

  useEffect(() => {
    searchfunction(searchInput);
  }, [searchInput]);
  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const exportToCSV = () => {
    const csvData = approvalData.map((obj) => ({
      Vehicle_ID: obj.vehicle_id,
      Date_of_Request: obj.date_of_request,
      Date_of_Approval: obj.ate_of_approval,
      Description_of_Issue: obj.desc_of_issue,
      Request_Letter: obj.Request_Letter,
      To_Approval_Document: obj.To_Approval_Document,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "approval_data.csv");
  };

  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            {/* <div>
            <button className="p-2 border border-black rounded-[12px]">
              <div className="flex items-center gap-3">
                Filter <RiMenu5Fill />
              </div>
            </button>
          </div> */}

            <div className="relative">
              {/* <button 
      className="p-2 border border-black rounded-[12px]" 
      // onClick={toggleDropdown}
    >
      <div className="flex items-center gap-3">
        Filter <RiMenu5Fill />
      </div>
    </button> */}
              <select
                name="vehicle_type"
                className="p-2 border border-black rounded-[12px]"
                // value={vehicleDetails.vehicle_type}
                // onChange={handleInputChange}
              >
                <option value="">Select Vehicle Type</option>
                <option value="ACTIVE">CAR</option>
                <option value="INACTIVE">VAN</option>
                <option value="INACTIVE">SUV</option>
              </select>
            </div>
          </div>
          <div className="flex items-center gap-2 text-black">
            {/* <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
            <FaCalendarAlt className="w-5 h-5 text-gray-400" />

            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="d MMM, yyyy"
              className="text-sm text-gray-600 focus:outline-none"
              value="Start Date"
            />

            <span className="text-gray-400">-</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="d MMM, yyyy"
              className="text-sm text-gray-600 focus:outline-none"
              value="End Date"
            />
          </div> */}
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex items-center gap-3">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Date of Request
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Date of Approval
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Description of Issue
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Request Letter
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              To Approval Document
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {approvalData.length > 0 ? (
            approvalData.map((item) => (
              <tr key={item.UploadRequestLetterTableID}>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.vehicle_id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.date_of_request}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.date_of_approval}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.desc_of_issue}
                </td>

                {/* a pdf need to load to this column */}
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.RequestLetter}
                </td>
                {/* a pdf need to load to this column */}
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  {item.ToApprovalDocument}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={togglePopup}
                  >
                    <AiTwotoneEdit />
                  </button>
                  <button
                    className="ml-2 text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteClick(item)}
                  >
                    <MdDeleteOutline />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No data found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* include Edit Fuel Consumption Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditFuelCostUpdateDataPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadRequestLettertable;
