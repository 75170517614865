/** @format */

import React, { useEffect, useState } from "react";
import { IoIosCloudUpload } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";

import TimePicker from "react-time-picker";

function Guard_room_form({ togglePopup_2, scheduleId }) {
  // You can now use the `scheduleId` prop in this component
  // console.log("Schedule ID:", scheduleId);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [guardRoomFormDetails, setGuardRoomFormDetails] = useState({
    departure_time: "",
    mileage_before_depature: "",
    arrived_time: "",
    mileage_after_returning: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleOpenModal = () => {
    if (uploadedFiles.length <= 0) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    const putData = {
      mileage_before_depature: guardRoomFormDetails.mileage_before_depature,
      mileage_after_returning: guardRoomFormDetails.mileage_after_returning,
      arrived_time: guardRoomFormDetails.arrived_time,
      departure_time: guardRoomFormDetails.departure_time,
    };

    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/dailyRoutesDispatch/releaseDispatch?schedule_id=${scheduleId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(putData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      const responseData = await response.json();
      setSuccessMessage("Guard room form submitted successfully");
      // Add a delay before closing the popup
      setTimeout(() => {
        togglePopup_2(); // Close the popup after a delay
      }, 3000); // 1000 milliseconds = 1 second
      setErrorMessage(""); // Clear any previous error message
    } catch (error) {
      console.error("Error submitting data:", error.message);
      setErrorMessage("Error submitting data");
      setSuccessMessage(""); // Clear any previous success message
    }

    setIsSaveConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setGuardRoomFormDetails({
      // set proper data
      departure_time: "",
      mileage_before_depature: "",
      arrived_time: "",
      mileage_after_returning: "",
    });
    setIsResetConfirmationOpen(false);
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Create Guard Room Form</h2>
          <button
            type="button"
            onClick={togglePopup_2}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Success Alert */}
        {successMessage && (
          <div
            className="p-4 mt-4 text-green-700 bg-green-100 border-l-4 border-green-500"
            role="alert"
          >
            <p className="font-bold">Success</p>
            <p>{successMessage}</p>
          </div>
        )}

        {/* Error Alert */}
        {errorMessage && (
          <div
            className="p-4 mt-4 text-red-700 bg-red-100 border-l-4 border-red-500"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{errorMessage}</p>
          </div>
        )}

        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Departure Time */}
              <div className="relative">
                <div>
                  <label className="block text-gray-700">Departure Time*</label>
                  <input
                    name="departureTime"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    type="time"
                    value={guardRoomFormDetails.departure_time}
                    onChange={(e) =>
                      setGuardRoomFormDetails({
                        ...guardRoomFormDetails,
                        departure_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {/* Milage Reading on Departure */}
              <div>
                <label className="block text-gray-700">
                  Milage Reading on Departure*
                </label>
                <input
                  type="number"
                  name="driverName"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={guardRoomFormDetails.mileage_before_depature}
                  onChange={(e) =>
                    setGuardRoomFormDetails({
                      ...guardRoomFormDetails,
                      mileage_before_depature: e.target.value,
                    })
                  }
                />
              </div>

              {/* Arrival Time */}
              <div>
                <label className="block text-gray-700">Arrival Time*</label>
                <input
                  name="arrivalTime"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  type="time"
                  value={guardRoomFormDetails.arrived_time}
                  onChange={(e) =>
                    setGuardRoomFormDetails({
                      ...guardRoomFormDetails,
                      arrived_time: e.target.value,
                    })
                  }
                />
              </div>

              {/* Milage Reading on Arrival  */}
              <div>
                <label className="block text-gray-700">
                  Milage Reading on Arrival *
                </label>
                <input
                  type="number"
                  name="emailAddress"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={guardRoomFormDetails.mileage_after_returning}
                  onChange={(e) =>
                    setGuardRoomFormDetails({
                      ...guardRoomFormDetails,
                      mileage_after_returning: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Guard_room_form;
