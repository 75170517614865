import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { IoIosCloudUpload } from "react-icons/io";
import FileUpload from "./upload_files";
import { IoIosCloseCircleOutline } from "react-icons/io";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import axios from "axios";

function FuelCostUpdateDataPopup({ togglePopup }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fuelDetails, setFuelDetails] = useState({
    fuel_type: "",
    effective_data: "",
    fuel_cost_per_liter: "",
  });
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleOpenModal = () => {
    if (uploadedFiles.length <= 0) {
      setIsModalOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFuelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDriverID = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/driver/get-all-drivers-employee-number`
      );
      const data = await response.json();

      const numbers = data.map((item) => item.employee_no);
      setEmployeeNumbers(numbers);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    getDriverID();
  }, []);

  // const handleSuggestionClick = (suggestion) => {
  //   setDriverDetails((prevDetails) => ({
  //     ...prevDetails,
  //     employeeNumber: suggestion,
  //   }));
  //   setShowSuggestions(false);
  //   fetchDriverDetails(suggestion);
  // };

  // const fetchDriverDetails = (employeeNo) => {
  //   fetch(
  //     `${backendUrl}/driver/get-driver-data-for-Employee-Number?employee_no=${employeeNo}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setDriverDetails((prevDetails) => ({
  //         ...prevDetails,
  //         driverName: data[0].employee_fullname,
  //         phoneNumber: data[0].employee_contact_no,
  //         emailAddress: data[0].employee_email,
  //       }));
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching driver details:", error);
  //     });
  // };

  const handleDateChange = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setFuelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: formattedDate,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/fuelDetails/`,
        fuelDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Fuel details saved successfully.");
      } else if (response.status === 400) {
        toast.error(response.data[0].msg);
      } else {
        toast.error("Failed to submit Vehicle data");
      }
    } catch (error) {
      // console.log(error.response);
      toast.error(error.response.data.error);
    }

    setIsSaveConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setFuelDetails({
      fuel_type: "",
      effective_data: "",
      fuel_cost_per_liter: "",
    });
    setIsResetConfirmationOpen(false);
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Fuel Cost Data</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* vehicle_type */}
              <div>
                <label className="block text-gray-700">Vehicle Type</label>
                <select
                  name="fuel_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelDetails.fuel_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="ACTIVE">CAR</option>
                  <option value="INACTIVE">VAN</option>
                  <option value="INACTIVE">SUV</option>
                </select>
              </div>

              {/* Effective Date */}
              <div>
                <label className="block text-gray-700">Effective Date *</label>
                <DatePicker
                  selected={fuelDetails.effective_data}
                  onChange={(date) => handleDateChange(date, "effective_data")}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/* Fuel Cost Per Liter */}
              <div>
                <label className="block text-gray-700">
                  Fuel Cost Per Liter*
                </label>
                <input
                  type="text"
                  name="fuel_cost_per_liter"
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelDetails.fuel_cost_per_liter}
                />
              </div>
            </div>
          </div>

          {/* <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button> */}

          {/* {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )} */}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default FuelCostUpdateDataPopup;
