/** @format */

import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { RiDownloadCloud2Line } from "react-icons/ri";
import axios from "axios";
import { IoAdd } from "react-icons/io5";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import Gate_pass_popup from "./add_gate_pass_popup";

import Guard_room_form from "./guard_room_form";

const Gate_pass_table = () => {
  const [gatePassData, setGatePassData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [isOpen_2, setIsOpen_2] = useState(false);
  // const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  const togglePopup_2 = (schedule_id) => {
    setSelectedScheduleId(schedule_id);
    setIsOpen_2((prev) => !prev); // Toggle the isOpen_2 state
  };

  const getGatePassData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/dailyRoutesDispatch`
      );
      const data = await response.json();
      setGatePassData(data);
      console.log("Gate passes :", data);
    } catch (error) {
      console.error("Error fetching Gate passed data:", error);
    }
  };

  useEffect(() => {
    getGatePassData();
  }, []);

  useEffect(() => {
    const closePopupAndFetchData = () => {
      if (!isOpen_2) {
        // Popup is closing
        getGatePassData();
      }
    };

    closePopupAndFetchData();
  }, [isOpen_2]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-5">
            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>

            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex gap-3 items-center">
                <div className="flex items-center gap-3">
                  <div>Export CSV</div>
                  <RiDownloadCloud2Line />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              GatePassID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Applicant’s Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Designation
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Department
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Requested Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Time
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Location
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Arrival Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Arrival Time
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Destination Location
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Estimated amount of Fuel liters
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Purpose of Transport
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Estimated KM
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Guard Room Form
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {gatePassData.length > 0 ? (
            gatePassData.map((item) => (
              <tr key={item.schedule_id}>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.schedule_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.applicant_name}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.designation}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.department}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.requested_date}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.departure_date}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.departure_time}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.departure_location}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.arrived_date}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.arrived_time}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.destination_location}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.estimated_amount_of_fuel}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.purpose_of_transport}
                </td>

                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {item.estimated_km}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <button
                    className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
                    onClick={() => togglePopup_2(item.schedule_id)}
                  >
                    <IoAdd /> Guard Room Form
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No gate passes found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Add Guard Room Form Popup */}
      {isOpen_2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Guard_room_form
              togglePopup_2={togglePopup_2}
              scheduleId={selectedScheduleId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gate_pass_table;
