import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import VehicleDetailsTable from "../../components/table/vehicle_parts_table";
import Fuel_consumption_data_table from "../../components/table/fuel_consumption_data_table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";
import FuelConsumptionPopup from "../../components/popup/fuel_consumption_popup";
import Fuel_cost_update_data_table from "../../components/table/fuel_cost_update_data_table";
import FuelCostUpdateDataPopup from "../../components/popup/fuel_cost_update_data_popup";
import Maintenance_records_table from "../../components/table/maintenance_records_table";
import Navbar from "../../components/navbar/navbar";


const Maintenance_records = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar/>
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6 gap-6">
          <div>
            <p className="text-[30px] font-semibold">Maintenance Records</p>
            <div className="flex align-left items-center gap-6">
              <p className="text-[15px] font-semibold text-black ">
                Vehicle Fleet Management System
              </p>
              <p className="text-[15px] font-semibold text-black">
                Maintenance & Repairs
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Maintenance Records
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        {/* <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Add fuel cost data
            </button>
          </div>
        </div> */}
      </div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by vehicle ID"
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div className="flex items-center text-black gap-2">
            {/* <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="h-5 w-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div> */}

            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex gap-3 items-center">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <Maintenance_records_table/>
      </div>

      {/* Add Fuel Consumption Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <FuelCostUpdateDataPopup togglePopup={togglePopup} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Maintenance_records;
