import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";

import moment from "moment";
import EditFuelConsumptionPopup from "../popup/edit_fuel_consumption";
import EditFuelCostUpdateDataPopup from "../popup/edit_fuel_cost_update_data";
import axios from "axios";

const Fuel_cost_update_data_table = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const currentDate = moment().format("MMMM Do YYYY");
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const rowsPerPage = 5;
  const [isOpen, setIsOpen] = useState(false);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleDeleteClick = (item) => {
    setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const getFuelCostData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/fuelDetails/`);
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };

  useEffect(() => {
    getFuelCostData();
  }, []);

  return (
    <div className="p-4">
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel type
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Effective Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel cost per Liter
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item) => (
            <tr key={item.fid}>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.fuelType}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.effectiveDate}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.fuelCostPerLiter}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-md">
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={togglePopup}
                >
                  <AiTwotoneEdit />
                </button>
                <button
                  className="ml-2 text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteClick(item)}
                >
                  <MdDeleteOutline />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* include Edit Fuel Consumption Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditFuelCostUpdateDataPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Fuel_cost_update_data_table;
