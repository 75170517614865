/** @format */

import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import EditFuelConsumptionPopup from "../popup/edit_fuel_consumption";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const Current_vehicle_dispatches_table = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // const currentDate = moment().format("MMMM Do YYYY");
  // const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // // const [employeeToDelete, setEmployeeToDelete] = useState(null);
  // const rowsPerPage = 5;

  const [isOpen, setIsOpen] = useState(false);
  const [vehicleDispatchData, setVehicleDispatchData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const totalPages = Math.ceil(data.length / rowsPerPage);

  // const currentData = data.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // );
  const handleDeleteClick = (item) => {
    // setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const exportToCSV = () => {
    const csvData = vehicleDispatchData.map((obj) => ({
      Vehicle_ID: obj.vehicle_id,
      Date_of_Request: obj.date_of_request,
      Date_of_Approval: obj.ate_of_approval,
      Description_of_Issue: obj.desc_of_issue,
      Request_Letter: obj.Request_Letter,
      To_Approval_Document: obj.To_Approval_Document,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "current-vehicle-dispatch-data.csv");
  };


  // Get for search method

  const getAllSearchTable = async () => {
    try {
      const res = await axios.get(`${backendUrl}/vehicle/get-all-vehicle-id`);
      // params: { search: query },

      console.log(res.data);
    } catch (error) {}
  };

  const getAllVehicleDispatches = async () => {
    try {
      const res = await axios.get(`${backendUrl}/dailyRoutesDispatch/`);
      setVehicleDispatchData(res.data);

      console.log(res.data);
    } catch (error) {
      console.error("Error fetching fuelsManagements details:", error);
    }
  };

  useEffect(() => {
    getAllVehicleDispatches();
  }, []);

  useEffect(() => {}, [getAllSearchTable]);

 
  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by Vehicle ID, Driver Name...."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div className="flex items-center gap-2 text-black">
            {/* <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="w-5 h-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div> */}
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex items-center gap-3">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Dispatch ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Driver Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Location
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Destination Location
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Departure Time
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Arrival Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Arrival Time
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Status
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {vehicleDispatchData.map((item) => (
            <tr key={item.schedule_id}>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.schedule_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.vehicle_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.driver_1_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.departure_location}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.destination_location}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.departure_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.departure_time}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.departure_time}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.arrived_time}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={togglePopup}
                >
                  <AiTwotoneEdit />
                </button>
                <button
                  className="ml-2 text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteClick(item)}
                >
                  <MdDeleteOutline />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* include Edit Fuel Consumption Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditFuelConsumptionPopup togglePopup={togglePopup} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Current_vehicle_dispatches_table;
