import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import EditFuelConsumptionPopup from "../popup/edit_fuel_consumption";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { RiDownloadCloud2Line } from "react-icons/ri";

const Fuel_consumption_data_table = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // const currentDate = moment().format("MMMM Do YYYY");
  // const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // // const [employeeToDelete, setEmployeeToDelete] = useState(null);
  // const rowsPerPage = 5;

  const [isOpen, setIsOpen] = useState(false);
  const [fuelConsumptionData, setFuelConsumptionData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const totalPages = Math.ceil(data.length / rowsPerPage);

  // const currentData = data.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // );
  const handleDeleteClick = (item) => {
    // setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  // const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // const searchfunction = async (query) => {
  //   try {
  //     const response = await axios.get(`${backendUrl}/fuelsManagements/get`, {
  //       params: { search: query },
  //     });
  //     setfuelConsumptionData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  // const getfuelConsumptionData = async () => {
  //   try {
  //     const response = await axios.get(`${backendUrl}/fuelsManagements/get`);
  //     setfuelConsumptionData(response.data);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  // useEffect(() => {
  //   getfuelConsumptionData();
  // }, []);

  // useEffect(() => {
  //   searchfunction(searchInput);
  // }, [searchInput]);

  // const handleSearchInputChange = (e) => {
  //   setSearchInput(e.target.value);
  // };

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  // Get all vehicel ID

  const getSearchVehicleIdORName = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/vehicle/get-all-vehicle-id`
        // {
        //   params: { search: query },
        // }
      );

      // console.log(res.data);
    } catch (error) {}
  };

  const getAllFlueData = async () => {
    try {
      const res = await axios.get(`${backendUrl}/fuelsManagements/get`);
      setFuelConsumptionData(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error("Error fetching fuelsManagements details:", error);
    }
  };

  useEffect(() => {
    getAllFlueData();
  }, []);

  useEffect(() => {
    getSearchVehicleIdORName();
  }, []);

  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            {/* <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div> */}

            <div className="relative">
              {/* <button 
        className="p-2 border border-black rounded-[12px]" 
        // onClick={toggleDropdown}
      >
        <div className="flex items-center gap-3">
          Filter <RiMenu5Fill />
        </div>
      </button> */}
              <select
                name="vehicle_type"
                className="p-2 border border-black rounded-[12px]"
                // value={vehicleDetails.vehicle_type}
                // onChange={handleInputChange}
              >
                <option value="">Select Vehicle Type</option>
                <option value="ACTIVE">CAR</option>
                <option value="INACTIVE">VAN</option>
                <option value="INACTIVE">SUV</option>
              </select>
            </div>
          </div>
          <div className="flex items-center gap-2 text-black">
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="w-5 h-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div>
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex items-center gap-3">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel Quantity(Liters)
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Distance Traveled (km)
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel efficiency (km/liter)
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Driver Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel Cost
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {fuelConsumptionData.map((item) => (
            <tr key={item.fuel_consumption_id}>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.vehicle_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.fuel_qty}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.distance_traveled}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.fuel_efficiency}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.driverName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.fuel_cost}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={togglePopup}
                >
                  <AiTwotoneEdit />
                </button>
                <button
                  className="ml-2 text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteClick(item)}
                >
                  <MdDeleteOutline />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* include Edit Fuel Consumption Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditFuelConsumptionPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Fuel_consumption_data_table;
