import React, { useEffect, useState } from "react";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import EditVehicleModel from "./edit_vehicle_model";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

const Vehicle_details_table = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [searchInput, setSearchInput] = useState("");
  const [pendingDocument, SetPendingDocument] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    vehicle_id: "",
    vehicle_name: "",
    model_number: "",
    make: "",
    year: "",
    registration_number: "",
    registration_document: "",
    mileage: "",
    status: "",
    insurance: "",
    service: "",
    last_maintenance_data: "",
    next_maintenance_data: "",
    next_maintenance_due: "",
    insurance_expiring_date: "",
    vehicle_fuel_type: "",
  });
  const [editOpen, setEditOpen] = useState(false);
  const [documentStatus, setDocumentStatus] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleViewDocument = (vehicleData) => {
    SetPendingDocument(false);
    setModalContent(vehicleData);
    // setDocumentOpen(true);
  };

  const handleEditDriver = (vehicleData) => {
    setModalContent(vehicleData);
    setEditOpen(true);
  };

  const closeModal = () => {
    setEditOpen(false);
    // setDocumentOpen(false);
    setModalContent(null);
  };

  const handleDeleteClick = async (vehicle) => {
    const { vehicle_id } = vehicle;
    console.log(vehicle_id);

    try {
      const response = await fetch(
        `${backendUrl}/removeVehicle?vehicle_id=${vehicle_id}`,
        {
          method: "PUT",
        }
      );

      console.log(response);

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error("Failed to delete vehicle:", errorMessage);
        // Show an error message to the user or handle the error
        alert("Failed to delete the vehicle.");
        return;
      }

      // Handle successful deletion (e.g., update the UI, show a success message)
      alert("Vehicle deleted successfully.");
      // Optionally, refresh the list of vehicles or update the state
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      // Handle network or other errors
      alert("An error occurred while deleting the vehicle.");
    }
  };

  const searchfunction = async () => {
    console.log(searchInput);
    try {
      const response = await axios.get(`${backendUrl}/vehicle/allVehicles`, {
        params: { search_term: searchInput },
      });

      setVehicleData(response.data);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };
  const getVehicleData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/vehicle/allVehicles`);
      setVehicleData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };
  useEffect(() => {
    getVehicleData();
  }, []);
  useEffect(() => {
    searchfunction(searchInput);
  }, [searchInput]);
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or any"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Plate No
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Model Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Fuel Type
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Make
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Year
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              vehicle_registration_no
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Registration DOC
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Milage
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Insurance EXP
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Last Maintenance Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Next Maintenance Date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {vehicleData.length > 0 ? (
            vehicleData.map((vehicle) => (
              <tr key={vehicle.vehicle_id}>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_id}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_name}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_no_plate_no}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_model}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_fuel_type}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_brand}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_year}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_registration_no}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {"doc"}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_mileage}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_status}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_insurance}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_last_maintenance_date}
                </td>
                <td className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
                  {vehicle.vehicle_next_maintenance_date}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEditDriver(vehicle)}
                  >
                    <AiTwotoneEdit />
                  </button>
                  <button
                    className="ml-2 text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteClick(vehicle)}
                  >
                    <MdDeleteOutline />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="py-4 text-center">
                No drivers found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {editOpen && modalContent && (
        <EditVehicleModel
          isOpen={editOpen}
          onClose={closeModal}
          vehicle={modalContent}
        />
      )}

      {/* {documentOpen && modalContent && (
        <ViewDocumentModel
          isOpen={documentOpen}
          onClose={closeModal}
          vehicle={modalContent}
        />
      )} */}
    </div>
  );
};
export default Vehicle_details_table;
