import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import FileUpload from "./upload_files";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function EditDriverModel({ isOpen, onClose, driver }) {
  console.log(driver);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [driverDetails, setDriverDetails] = useState({
    employee_number: "",
    license_number: "",
    driver_id: "",
    employee_fullname: "",
    employee_contact_no: "",
    employee_email: "",
  });

  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]); // Define state for uploaded files
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails({ ...driverDetails, [name]: value });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleRemoveFile = (index) => {
    // Remove the selected file from the uploaded files list
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleUpdateForm = async () => {
    try {
      const response = await axios.put(
        `${backendUrl}/driver/edit-details`,
        driverDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        await handleFileUpload(driverDetails.driver_id);
        toast.success(response.data.message);
        setTimeout(() => {}, 2000);
      } else {
        toast.error("Failed to Edit Driver Image Edit");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Edit Driver Data");
    }
    setIsSaveConfirmationOpen(false);
  };

  // File uploading part
  const handleFileUpload = async (driver_id) => {
    console.log(driver_id);
    console.log(uploadedFiles);

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("driver_id", driver_id);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const response = await axios.post(
        `${backendUrl}/driver/add-driver-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.success) {
        setUploadedFiles([]); // Clear the uploaded files
      } else {
        toast.error("Failed to upload files");
      }
    } catch (error) {
      toast.error("Error uploading files");
    }
  };

  useEffect(() => {
    setDriverDetails(driver);
  }, [driver]); // Add driver as a dependency

  if (!isOpen) return null;

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
    >
      <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
        <div className="relative w-full p-8 bg-white rounded-lg">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold">Edit Driver</h2>
            <button
              type="button"
              onClick={onClose}
              className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
            >
              <IoIosCloseCircleOutline />
            </button>
          </div>

          {/* Edit Driver Details Form */}
          <form>
            <div className="grid grid-cols-2 text-lg gap-y-6 gap-x-12">
              {/* Employee Number */}
              <div>
                <label className="block text-gray-700">Employee Number</label>
                <input
                  type="text"
                  name="employee_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_number}
                  readOnly
                  onChange={handleChange}
                />
              </div>

              {/* Employee Full Name */}
              <div>
                <label className="block text-gray-700">
                  Employee Full Name
                </label>
                <input
                  type="text"
                  name="employee_fullname"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_fullname}
                  onChange={handleChange}
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="text"
                  name="employee_contact_no"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_contact_no}
                  onChange={handleChange}
                />
              </div>

              {/* Email Address */}
              <div>
                <label className="block text-gray-700">Email Address</label>
                <input
                  type="text"
                  name="employee_email"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.employee_email}
                  onChange={handleChange}
                />
              </div>

              {/* License Number */}
              <div>
                <label className="block text-gray-700">License Number</label>
                <input
                  type="text"
                  name="license_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.license_number}
                  onChange={handleChange}
                />
              </div>

              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID</label>
                <input
                  type="text"
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={driverDetails.driver_id}
                  readOnly
                  onChange={handleChange}
                />
              </div>
            </div>

            <h1 className="text-[30px] font-bold mb-8 mt-10">
              Driver Registration Document
            </h1>
            <button
              type="button"
              onClick={handleOpenModal}
              className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
            >
              <div className="flex items-center gap-2">
                <div className="text-[15px] font-bold">
                  Click Here to Upload
                </div>
                <IoIosCloudUpload />
              </div>
            </button>

            {uploadedFiles.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="px-3 py-1 text-white bg-red-500 rounded"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
          </form>
          <div className="mt-5">
            <div className="flex items-center justify-between px-4 py-2">
              <div></div>
              <div className="flex gap-4">
                <button
                  onClick={handleOpenSaveConfirmation}
                  className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
            <p>Are you sure you want to save the form data?</p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-green-500 rounded-xl"
                onClick={handleUpdateForm}
              >
                Yes, Save
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseSaveConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default EditDriverModel;
