/** @format */

import moment from "moment";
import Navbar from "../components/navbar/navbar";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const Permission = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [checkedValues, setCheckedValues] = useState({});
  const [roleName, setRoleName] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [roleDescription, setRoleDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const permissionHierarchy = useMemo(
    () => ({
      5000: { children: [500, 510, 520] },
      500: { children: [6000] },
      510: { children: [6010] },
      520: { children: [6020] },
      5001: { children: [550, 560] },
      560: { children: [6100] },
      5002: { children: [580, 590, 600] },
      580: { children: [6200, 6210] },
      590: { children: [6220, 6230] },
      600: { children: [6240, 6250, 6260, 6270] },
      5003: { children: [640, 650, 660] },
      640: { children: [6300, 6310] },
      650: { children: [6350, 6360] },
      660: { children: [6380, 6390] },
      5004: { children: [690, 700, 710] },
      690: { children: [6400, 6410, 6420] },
      700: { children: [6430, 6440] },
      710: { children: [6450] },
      5005: { children: [720] },
      5006: { children: [730] },
      730: { children: [6500] },
      5007: { children: [740, 750, 760, 770] },
      740: { children: [6510, 6520, 6530] },
      750: { children: [6540] },
      760: { children: [6550] },
      770: { children: [6560] },
      5008: { children: [780, 790, 800, 810] },
      780: { children: [6570, 6580, 6590] },
      790: { children: [6600] },
      800: { children: [6610] },
      810: { children: [6620] },
      5009: { children: [820, 830] },
      820: { children: [6630] },
      830: { children: [6640] },
      5010: { children: [840] },
      840: { children: [6650] },
      5011: { children: [850] },
      850: { children: [6660, 6670, 6680] },
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }
      return newValues;
    });
  };

  const handleSave = async () => {
    if (!roleName || !roleDescription) {
      window.alert("Role Name and Role Description cannot be empty");
      return;
    }

    const permissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    const postData = {
      role_name: roleName,
      role_description: roleDescription,
      permissions,
    };

    console.log("Send data:", postData);

    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/rolePermissions/addPermissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);

          // Reset form values
          setRoleName("");
          setRoleDescription("");
          setCheckedValues({});

          navigate("/permission");
        }, 2000); // Show message for 2 seconds before navigating
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-2xl font-semibold">Create User Permissions</p>
          <p className="text-lg font-semibold text-[#ABDCB0]">Permission</p>
        </div>
        <div className="flex gap-6 items-center">
          <div className="text-xl font-bold text-[#069855]">{currentDate}</div>
          <div className="text-xl font-bold">{currentTime}</div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Role:
          </label>
          <input
            type="text"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Role Description:
          </label>
          <input
            type="text"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
        {/*  Vehicle Information Management */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">
              Vehicle Information Management
            </h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="500"
                  checked={!!checkedValues[500]}
                  onChange={() => handleCheckboxChange(500)}
                />
                Vehicle Details
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[{ value: 6000, label: "Add Vehicle" }].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 500)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="510"
                checked={!!checkedValues[510]}
                onChange={() => handleCheckboxChange(510)}
              />
              Vehicle Part Details
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              {[{ value: 6010, label: "Add Vehicle Part" }].map((item) => (
                <label key={item.value} className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value={item.value}
                    checked={!!checkedValues[item.value]}
                    onChange={() => handleCheckboxChange(item.value, 510)}
                  />
                  {item.label}
                </label>
              ))}
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="520"
                checked={!!checkedValues[520]}
                onChange={() => handleCheckboxChange(520)}
              />
              Driver Details
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              {[{ value: 6020, label: "Add Driver" }].map((item) => (
                <label key={item.value} className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value={item.value}
                    checked={!!checkedValues[item.value]}
                    onChange={() => handleCheckboxChange(item.value, 520)}
                  />
                  {item.label}
                </label>
              ))}
            </div>
          </div>
        </div>

        {/*     Route Planinng and Optimization */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">
              Route Planinng and Optimization
            </h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="550"
                  checked={!!checkedValues[550]}
                  onChange={() => handleCheckboxChange(550)}
                />
                Schedule Daily routes
              </label>
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="560"
                checked={!!checkedValues[560]}
                onChange={() => handleCheckboxChange(560)}
              />
              Manager Transportation Schedules
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              {[
                { value: 6100, label: "Add Manager Transportation Schedules" },
              ].map((item) => (
                <label key={item.value} className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value={item.value}
                    checked={!!checkedValues[item.value]}
                    onChange={() => handleCheckboxChange(item.value, 560)}
                  />
                  {item.label}
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* Fuel Management */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Fuel Management</h2>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="580"
                checked={!!checkedValues[580]}
                onChange={() => handleCheckboxChange(580)}
              />
              Fuel Consumption Data
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6200"
                  checked={!!checkedValues[6200]}
                  onChange={() => handleCheckboxChange(6200, 580)}
                />
                Add Fuel consumption data
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6210"
                  checked={!!checkedValues[6210]}
                  onChange={() => handleCheckboxChange(6210, 580)}
                />
                Consumption Export CSV
              </label>
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="590"
                checked={!!checkedValues[590]}
                onChange={() => handleCheckboxChange(590)}
              />
              Fuel Cost Update
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6220"
                  checked={!!checkedValues[6220]}
                  onChange={() => handleCheckboxChange(6220, 590)}
                />
                Add Fuel Cost Data
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6230"
                  checked={!!checkedValues[6230]}
                  onChange={() => handleCheckboxChange(6230, 590)}
                />
                Fuel Cost Export CSV
              </label>
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="600"
                checked={!!checkedValues[600]}
                onChange={() => handleCheckboxChange(600)}
              />
              Generate Monthly Fuel Reports
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6240"
                  checked={!!checkedValues[6240]}
                  onChange={() => handleCheckboxChange(6240, 600)}
                />
                Total Fuel Consumed Card
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6250"
                  checked={!!checkedValues[6250]}
                  onChange={() => handleCheckboxChange(6250, 600)}
                />
                Total Fuel Cost Card
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6260"
                  checked={!!checkedValues[6260]}
                  onChange={() => handleCheckboxChange(6260, 600)}
                />
                Fuel Consumption Graph
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6270"
                  checked={!!checkedValues[6270]}
                  onChange={() => handleCheckboxChange(6270, 600)}
                />
                Fuel Reports Export CSV
              </label>
            </div>
          </div>
        </div>

        {/* Maintenance & Repairs */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Maintenance & Repairs</h2>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="640"
                checked={!!checkedValues[640]}
                onChange={() => handleCheckboxChange(640)}
              />
              Upload Request Letter Form / Approval
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6300"
                  checked={!!checkedValues[6300]}
                  onChange={() => handleCheckboxChange(6300, 640)}
                />
                Request Letter Form
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6310"
                  checked={!!checkedValues[6310]}
                  onChange={() => handleCheckboxChange(6310, 640)}
                />
                Request Letter Form Export CSV
              </label>
            </div>

            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="650"
                checked={!!checkedValues[650]}
                onChange={() => handleCheckboxChange(650)}
              />
              Maintenance Records
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6350"
                  checked={!!checkedValues[6350]}
                  onChange={() => handleCheckboxChange(6350, 650)}
                />
                Maintenance Export CSV
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6360"
                  checked={!!checkedValues[6360]}
                  onChange={() => handleCheckboxChange(6360, 650)}
                />
                Actions
              </label>
            </div>
            <label className="flex items-center text-[#069855] mt-4">
              <input
                type="checkbox"
                className="mr-2"
                value="660"
                checked={!!checkedValues[660]}
                onChange={() => handleCheckboxChange(660)}
              />
              Breakdown Records
            </label>
            <div className="ml-6 grid grid-cols-1 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6380"
                  checked={!!checkedValues[6380]}
                  onChange={() => handleCheckboxChange(6380, 660)}
                />
                Add a Breakdown Record
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="6390"
                  checked={!!checkedValues[6390]}
                  onChange={() => handleCheckboxChange(6390, 660)}
                />
                Breakdown Records Export CSV
              </label>
            </div>
          </div>
        </div>

        {/* Inventory Management */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Inventory Management</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="690"
                  checked={!!checkedValues[690]}
                  onChange={() => handleCheckboxChange(690)}
                />
                Vehicle Count Dashboard
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6400, label: "Total Vehicle Count Card" },
                  { value: 6410, label: "Operational Vehicle Count Card" },
                  {
                    value: 6420,
                    label: "Under Maintenance Vehcile Count Card",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 690)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="700"
                  checked={!!checkedValues[700]}
                  onChange={() => handleCheckboxChange(700)}
                />
                Fuel Consumption Dashboard
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6430, label: "Total Fuel Consumed" },
                  { value: 6440, label: "Average Fuel Efficiency" },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 700)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="710"
                  checked={!!checkedValues[710]}
                  onChange={() => handleCheckboxChange(710)}
                />
                Location Data Dashboard
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6450, label: "Location Data Dashboard Export CSV" },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 710)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Driver Performance Monitoring */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Driver Performance Monitoring</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="720"
                  checked={!!checkedValues[720]}
                  onChange={() => handleCheckboxChange(720)}
                />
                Monthly Driver Performance
              </label>
            </div>
          </div>
        </div>
        {/* Compliance & Reporting */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Compliance & Reporting</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="730"
                  checked={!!checkedValues[730]}
                  onChange={() => handleCheckboxChange(730)}
                />
                Vehicles Exceeding 5000km Table
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  {
                    value: 6500,
                    label: "Vehicles Exceeding 5000 km Export CSV",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 730)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Approval Process 01 */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Approval Process 01</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="740"
                  checked={!!checkedValues[740]}
                  onChange={() => handleCheckboxChange(740)}
                />
                Approval Process -Approve 01
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6510, label: "Document Uploading" },
                  { value: 6520, label: "System Editing" },
                  {
                    value: 6530,
                    label: "Gate Pass Request",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 740)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="750"
                  checked={!!checkedValues[750]}
                  onChange={() => handleCheckboxChange(750)}
                />
                Approval Process Approve 01 Documents Uploading
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6540, label: "Documents Uploading  Export CSV" },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 750)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="760"
                  checked={!!checkedValues[760]}
                  onChange={() => handleCheckboxChange(760)}
                />
                Approval Process Approve 01 System Editing
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[{ value: 6550, label: "System Editing  Export CSV" }].map(
                  (item) => (
                    <label key={item.value} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value={item.value}
                        checked={!!checkedValues[item.value]}
                        onChange={() => handleCheckboxChange(item.value, 760)}
                      />
                      {item.label}
                    </label>
                  )
                )}
              </div>
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="770"
                  checked={!!checkedValues[770]}
                  onChange={() => handleCheckboxChange(770)}
                />
                Approval Process Approve 01 Gate Pass
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[{ value: 6560, label: "Gate Pass Request Export CSV" }].map(
                  (item) => (
                    <label key={item.value} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value={item.value}
                        checked={!!checkedValues[item.value]}
                        onChange={() => handleCheckboxChange(item.value, 770)}
                      />
                      {item.label}
                    </label>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Approval Process 02 */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Approval Process 02</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="780"
                  checked={!!checkedValues[780]}
                  onChange={() => handleCheckboxChange(780)}
                />
                Approval Process -Approve 02
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6570, label: "Document Uploading Approve 02" },
                  { value: 6580, label: "System Editing Approve 02" },
                  {
                    value: 6590,
                    label: "Gate Pass Request Approve 02",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 780)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="790"
                  checked={!!checkedValues[790]}
                  onChange={() => handleCheckboxChange(790)}
                />
                Approval Process Approve 02 Documents Uploading
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  {
                    value: 6600,
                    label: "Documents Uploading  Export CSV Approve 02",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 790)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="800"
                  checked={!!checkedValues[800]}
                  onChange={() => handleCheckboxChange(800)}
                />
                Approval Process Approve 02 System Editing
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  {
                    value: 6610,
                    label: "System Editing  Export CSV Approve 02",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 800)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="810"
                  checked={!!checkedValues[810]}
                  onChange={() => handleCheckboxChange(810)}
                />
                Approval Process Approve 02 Gate Pass
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  {
                    value: 6620,
                    label: "Gate Pass Request Export CSV Approve 02",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 810)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Approval Process 03 */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Approval Process 03</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="820"
                  checked={!!checkedValues[820]}
                  onChange={() => handleCheckboxChange(820)}
                />
                Approval Process -Approve 03
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[{ value: 6630, label: "Gate Pass Request Approve 03" }].map(
                  (item) => (
                    <label key={item.value} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value={item.value}
                        checked={!!checkedValues[item.value]}
                        onChange={() => handleCheckboxChange(item.value, 820)}
                      />
                      {item.label}
                    </label>
                  )
                )}
              </div>

              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="830"
                  checked={!!checkedValues[830]}
                  onChange={() => handleCheckboxChange(830)}
                />
                Approval Process Approve 03 Gate Pass
              </label>
              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  {
                    value: 6640,
                    label: "Gate Pass Request Export CSV Approve 03",
                  },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 830)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Permission */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Permission</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="840"
                  checked={!!checkedValues[840]}
                  onChange={() => handleCheckboxChange(840)}
                />
                permissions
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[{ value: 6650, label: "Add Permission role" }].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 840)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Gate Pass */}
        <div>
          <div className="border bg-gray-50 rounded-md p-4">
            <h2 className="text-lg font-bold">Gate Pass</h2>
            <div className="flex flex-col mt-4">
              <label className="flex items-center text-[#069855]">
                <input
                  type="checkbox"
                  className="mr-2"
                  value="850"
                  checked={!!checkedValues[850]}
                  onChange={() => handleCheckboxChange(850)}
                />
                Gate Pass Requests
              </label>

              <div className="ml-6 grid grid-cols-1 gap-2">
                {[
                  { value: 6660, label: "Create a Gate Pass Request " },
                  { value: 6670, label: "Guard Room Form" },
                  { value: 6680, label: "Gate Pass Requests Export CSV" },
                ].map((item) => (
                  <label key={item.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={item.value}
                      checked={!!checkedValues[item.value]}
                      onChange={() => handleCheckboxChange(item.value, 850)}
                    />
                    {item.label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <p>Role saved successfully!</p>
          </div>
        </div>
      )}

      {/* Save and Cancel buttons */}
      <div className="flex justify-end gap-5 mt-10">
        <button
          className="text-[#069855] bg-white border border-black px-4 py-2 rounded-md shadow-sm"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-[#069855] text-white rounded-md shadow-sm"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Permission;
