/** @format */
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/sidebar/sidebar.jsx";
import Login from "./pages/login/login.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";

// Vehicle Information Management
import Driver_Details from "./pages/driver_details/driver_details.jsx";
import Vehicle_Parts_Details from "./pages/vehicle_part_details/vehicle_part_details.jsx";
import Vehicle_Details from "./pages/vehicle_details/vehicle_details.jsx";

import Schedule_Daily_Routes from "./pages/schedule_daily_routes/schedule_daily_routes.jsx";

// Current Vehicle Dispatches
import Current_vehicle_dispatches from "./pages/Current Vehicle Dispatches/current_vehicle_dispatches.jsx";

// Route Planning and Optimization
import Manager_transportation_details from "./pages/route_planning_and_optimization/manager_transportation_schedules/manager_transportation_details.jsx";
import Gate_Pass_Requests from "./pages/route_planning_and_optimization/gate_pass_requests/gate_Pass_Requests.jsx";
import Gate_Pass_Requests_Approvals from "./pages/route_planning_and_optimization/gate_pass_requests_approvals/gate_pass_requests_ approvals_details.jsx";

import Add_vehicle from "./pages/vehicle_details/add_vehicle_popup.jsx";
import Fuel_consumption_data from "./pages/Fuel_Management/fuel_consumption_data.jsx";
import Fuel_cost_update_data from "./pages/Fuel_Management/fuel_cost_update_data.jsx";
import GenerateMonthlyFuelReport from "./pages/Fuel_Management/Generate_Monthly_Fuel_Reports.jsx";
import Maintenance_records from "./pages/Maintenance & Repairs/maintenance_records.jsx";

// Upload_Request_Letter_Form
import Upload_Request_Letter_Form from "./pages/upload_request_letter_form/upload_request_letter_form.jsx";
import Breakdown_records from "./pages/Maintenance & Repairs/breakdown_records.jsx";

// Inventory Management
import Vehicle_Count_Dashboard from "./pages/Inventory_management/vehicle_Count_Dashboard.jsx";
import Fuel_consumption_dashboard from "./pages/Inventory_management/fuel_consumption_dashboard.jsx";
import Location_data_dashboard from "./pages/Inventory_management/location_data_dashboard.jsx";
import Monthly_Driver_Performance from "./pages/driver_performance_monitoring/monthly_driver_performance.jsx";
import Vehicles_exceeding_5000km from "./pages/Compliance & Reporting/vehicles_exceeding_5000km.jsx";
import Approval_process_01 from "./pages/Approval Process/Approval Process/approval_process.jsx";
import Documents_Uploading from "./pages/Approval Process/Approve 1/documents_Uploading.jsx";
import Documents_Uploading_Approve02 from "./pages/Approval Process/Approve 2/approve_02_Documents_Uploading.jsx";
import Vehicle_Documents_Uploading from "./pages/Approval Process/Approve 1/vehicle_Documents_Uploading.jsx";
import Driver_Documents_Uploading from "./pages/Approval Process/Approve 1/driver_Documents_Uploading.jsx";
import Vehicle_Documents_Uploading_Approve02 from "./pages/Approval Process/Approve 2/vehicle_Documents_Uploading_Approve02.jsx";
import Driver_Documents_Uploading_Approve02 from "./pages/Approval Process/Approve 2/driver_Documents_Uploading_Approve02.jsx";
import System_Editing from "./pages/Approval Process/Approve 1/system_Editing.jsx";
import Gate_pass from "./pages/Approval Process/Approve 1/gate_pass.jsx";
import Approval_process_02 from "./pages/Approval Process/Approve 2/approval_process_02.jsx";
import Approve_02_Documents_Uploading from "./pages/Approval Process/Approve 2/approve_02_Documents_Uploading.jsx";
import Approve_02_system_editing from "./pages/Approval Process/Approve 2/approve_02_system_editing.jsx";
import Permission from "./pages/permission.jsx";

import Approval_process_03 from "./pages/Approval Process/Approve 3/approval_process_03.jsx";
import Approve_02_gate_pass from "./pages/Approval Process/Approve 2/approval_02_gate_pass.jsx";
import Approve_03_gate_pass from "./pages/Approval Process/Approve 3/approve_03_gate_pass.jsx";




const AppContent = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const showSidebar = location.pathname !== "/login";
  return (
    <div className="flex App">
      {showSidebar && (
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-grow p-5 transition-all duration-300 ${
          isSidebarOpen && showSidebar ? "ml-64" : "ml-20"
        }`}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/vehicle-details" element={<Vehicle_Details />} />
          <Route
            path="/vehicle-part-details"
            element={<Vehicle_Parts_Details />}
          />
          <Route path="/driver-details" element={<Driver_Details />} />
          <Route
            path="/schedule-daily-routes"
            element={<Schedule_Daily_Routes />}
          />
          <Route
            path="/current_vehicle_dispatches"
            element={<Current_vehicle_dispatches />}
          />
          <Route
            path="/manager-transportation-schedules"
            element={<Manager_transportation_details />}
          />
          <Route path="/gate_pass_requests" element={<Gate_Pass_Requests />} />
          <Route
            path="/gate_pass_requests_approvals"
            element={<Gate_Pass_Requests_Approvals />}
          />

          <Route
            path="/upload-request-letter-form"
            element={<Upload_Request_Letter_Form />}
          />
          <Route path="/Add-vehicle" element={<Add_vehicle />} />
          <Route
            path="/Fuel-consumption-data"
            element={<Fuel_consumption_data />}
          />
          <Route path="/fuel-cost-update" element={<Fuel_cost_update_data />} />
          <Route
            path="/generate-monthly-fuel-reports"
            element={<GenerateMonthlyFuelReport />}
          />
          <Route
            path="/Fuel-consumption-data"
            element={<Fuel_consumption_data />}
          />
          <Route
            path="/Upload-Request-Letter-Form"
            element={<Upload_Request_Letter_Form />}
          />
          <Route
            path="/maintenance-records"
            element={<Maintenance_records />}
          />
          <Route path="/Breakdown-records" element={<Breakdown_records />} />
          <Route
            path="/vehicle-count-dashboard"
            element={<Vehicle_Count_Dashboard />}
          />
          <Route
            path="/fuel-consumption-data"
            element={<Fuel_consumption_data />}
          />
          {/* inventory management */}
          <Route
            path="/fuel-consumption-dashboard"
            element={<Fuel_consumption_dashboard />}
          />
          <Route
            path="/Location-data-dashboard"
            element={<Location_data_dashboard />}
          />
          {/* Driver Performance Monitoring */}
          <Route
            path="/Monthly-Driver-Performance"
            element={<Monthly_Driver_Performance />}
          />
          {/* Compliance and Reporting */}
          <Route
            path="/vehicles-exceeding-5000km-table"
            element={<Vehicles_exceeding_5000km />}
          />
          {/* Approval process */}
          <Route
            path="/Approval-process-01"
            element={<Approval_process_01 />}
          />
          {/* Approval process 01 */}
          <Route
            path="/Approval-process-01"
            element={<Approval_process_01 />}
          />
          <Route
            path="/Approve-01-Documents-Uploading"
            element={<Documents_Uploading />}
          />
          <Route
            path="/Approve-02-Documents-Uploading"
            element={<Documents_Uploading_Approve02 />}
          />
          <Route
            path="/Approve-01-Vehicle-Documents-Uploading"
            element={<Vehicle_Documents_Uploading />}
          />
          <Route
            path="/Approve-02-Driver-Documents-Uploading"
            element={<Driver_Documents_Uploading_Approve02 />}
          />
          <Route
            path="/Approve-02-Vehicle-Documents-Uploading"
            element={<Vehicle_Documents_Uploading_Approve02 />}
          />
          <Route
            path="/Approve-01-Driver-Documents-Uploading"
            element={<Driver_Documents_Uploading />}
          />
          <Route
            path="/approve-01-System-Editing"
            element={<System_Editing />}
          />
          <Route path="/approve-01-Gate-Pass" element={<Gate_pass />} />
          {/* Approval process 02 */}
          <Route
            path="/approval_process_02"
            element={<Approval_process_02 />}
          />
          <Route
            path="/approve_02_Documents_Uploading"
            element={<Approve_02_Documents_Uploading />}
          />
          <Route
            path="/approve-02-System-Editing"
            element={<Approve_02_system_editing />}
          />
          {/* <Route path="approve-01-Gate-Pass" element={<Gate_pass />} /> */}
          {/* User Permission */}
          <Route
            path="/approve-02-gate-pass"
            element={<Approve_02_gate_pass />}
          />
          {/* Approval process 03 */}
          <Route
            path="/approval-process-03"
            element={<Approval_process_03 />}
          />
          {/* <Route path="approve-01-Gate-Pass" element={<Gate_pass />} /> */}
          <Route
            path="/approve-03-gate-Pass"
            element={<Approve_03_gate_pass />}
          />
          {/* User Permission */}
          <Route path="/permission" element={<Permission />} />
        </Routes>
      </div>
    </div>
  );
};
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <Router>
      <AppContent isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </Router>
  );
}
export default App;
