import React from "react";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";

const Vehicle_parts_table = () => {
  return (
    <div className="p-4">
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Name"
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div>
            <input
              type="date"
              className="border border-black rounded-xl p-2 "
            />
          </div>
        </div>
      </div>
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Vehicle Name
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Model Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Make
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Year
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Registration Number
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Registration Document
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              mileage
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              status
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              insurance
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              service
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              last maintenance date
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              next maintenance due
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200"></tbody>
      </table>
    </div>
  );
};

export default Vehicle_parts_table;
