import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";

import moment from "moment";
import EditFuelConsumptionPopup from "../popup/edit_fuel_consumption";
import EditFuelCostUpdateDataPopup from "../popup/edit_fuel_cost_update_data";
import EditMaintenaceRecordPopup from "../popup/edit_Maintenance_Record_popup";
import EditBreakdownRecordPopup from "../popup/edit_breakdown_record_popup";

const Approve_01_system_editing_table = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const rowsPerPage = 5;
  const [isOpen, setIsOpen] = useState(false);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleDeleteClick = (item) => {
    setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  return (
    <div className="p-4">
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Employee ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Description
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approval 01
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((item) => (
            <tr key={item.Approv01SystemEditingTableID}>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.EmployeeID}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.Description}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.Approval01}
              </td>

              {/* <td className="px-6 py-4 whitespace-nowrap text-md">
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={togglePopup}
                >
                  <AiTwotoneEdit />
                </button>
                <button
                  className="text-red-500 hover:text-red-700 ml-2"
                  onClick={() => handleDeleteClick(item)}
                >
                  <MdDeleteOutline />
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* include Edit breakdown records Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditBreakdownRecordPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Approve_01_system_editing_table;
