/** @format */

import moment from "moment";
import { CiSearch } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FiAlertOctagon } from "react-icons/fi";
import Navbar from "../../../components/navbar/navbar";
import { Link } from "react-router-dom";

const Approval_process_02 = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };
  const [selectedDepartment, setSelectedDepartment] = useState("All Vehicles");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useState("All Departments");
  const [departments, setDepartments] = useState([]);
  const rowsPerPage = 5;
  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDropdownOpen(false);
  };

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6 gap-6">
          <div>
            <p className="text-[30px] font-semibold">Approve 02</p>
            <div className="flex align-left items-center gap-6">
              <p className="text-[15px] font-semibold text-black ">
                Vehicle Fleet Management System
              </p>
              <p className="text-[15px] font-semibold text-black">
                Approval Process
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Approve 02
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by vehicle ID"
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />
              <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" />
            </div>

            <div className="relative">
              <button
                className="p-3 border border-black rounded-[12px]"
                onClick={toggleDropdown}
              >
                <div className="flex gap-3 items-center">
                  <div>{selectedDepartment}</div>
                  <MdKeyboardArrowDown />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    {departments.map((dept, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleDepartmentSelect(dept)}
                      >
                        {dept}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center text-black gap-2">
            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>
          </div>
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-3 grid-flow-rows gap-5">
          {/* card 1 */}
          <div className=" border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center">
              <div className="">
                {/* <p className="font-semibold text-black">Document Uploading</p> */}
                <p className="mt-5 font-semibold">Document Uploading</p>
              </div>
              <div>
                <FiAlertOctagon className="w-8 h-8 text-green-400" />
              </div>
            </div>
            <Link to="/Approve-02-Documents-Uploading">
              <button className=" text-white p-6 border border-[#069855] rounded-[25px] bg-[#069855] mt-5">
                view
              </button>
            </Link>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
          {/* card 2 */}
          <div className=" border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center">
              <div>
                <p className="mt-5 font-semibold">System Editing</p>
              </div>
              <div>
                <FiAlertOctagon className="w-8 h-8 text-green-400" />
              </div>
            </div>
            <Link to="/approve-02-System-Editing">
              <button className=" text-white p-6 border border-[#069855] rounded-[25px] bg-[#069855] mt-5">
                view
              </button>
            </Link>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
          {/* card 3 */}
          <div className=" border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center">
              <div>
                <p className="mt-5 font-semibold">Gate Pass Request</p>
              </div>
              <div>
                <FiAlertOctagon className="w-8 h-8 text-green-400" />
              </div>
            </div>
            <Link to="/approve-02-gate-Pass">
              <button className=" text-white p-6 border border-[#069855] rounded-[25px] bg-[#069855] mt-5">
                view
              </button>
            </Link>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
        </div>
      </div>
      {/* lower layer */}
    </div>
  );
};

export default Approval_process_02;
