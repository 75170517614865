import React from "react";

function Schedule_daily_routes() {
  return (
    <div>
      <h1>Schedule_daily_routes screen</h1>
    </div>
  );
}

export default Schedule_daily_routes;
