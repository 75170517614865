/** @format */

import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Approve_Gate_pass_popup from "../popup/approve_gate_pass_popup";
const Approve_02_gate_pass_table = () => {
  const currentDate = moment().format("MMMM Do YYYY");

  const [gatePassApprove02Data, setGatePassApprove02Data] = useState([]);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = (item = null) => {
    setSelectedItem(item);
    setIsOpen(!isOpen);
  };

  const updateApprovalStatus = async () => {
    if (!selectedItem) return;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/dailyRoutesSchedule/approval2?schedule_id=${selectedItem.schedule_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            approve2: selectedStatus,
            desc: selectedItem.desc,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update approval status.");
      }
      console.log("Approval status updated successfully!");
      getAllGatePassApprove02();
      handleCloseSaveConfirmation(); // Close the popup after saving
    } catch (error) {
      console.error("Error updating approval status:", error);
    }
  };

  const handleApprovalChange = (e, item) => {
    const status = e.target.value;
    setSelectedItem(item);
    setSelectedStatus(status);
    setIsSaveConfirmationOpen(true); // Open the confirmation popup
  };

  const getAllGatePassApprove02 = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/dailyRoutesSchedule/getReqApprove2`
      );
      const data = await response.json();
      setGatePassApprove02Data(data);
    } catch (error) {
      console.error("Error fetching vehicle upload approve02 details:", error);
    }
  };

  useEffect(() => {
    getAllGatePassApprove02();
  }, []);

  const handleTextareaChange = (e, item) => {
    const updatedData = gatePassApprove02Data.map((dataItem) =>
      dataItem.schedule_id === item.schedule_id
        ? { ...dataItem, desc: e.target.value }
        : dataItem
    );
    setGatePassApprove02Data(updatedData);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
    setSelectedItem(null);
    setSelectedStatus("");
  };

  return (
    <div className="p-4">
      <table className="min-w-full mt-10 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Gate Pass ID
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Document
            </th>
            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approved Status 02 Description
            </th>

            <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-500 uppercase text-md">
              Approval 02
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {gatePassApprove02Data.map((item) => (
            <tr key={item.schedule_id}>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.schedule_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                {item.schedule_id ? (
                  <button
                    onClick={() => togglePopup(item)} // Pass the item data
                    className="px-3 py-1 rounded-full bg-green-100 rounded-xl text-green-600 hover:underline"
                  >
                    View
                  </button>
                ) : (
                  <span className="px-3 py-1 rounded-xl bg-red-100 text-red-700">
                    No Document
                  </span>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md">
                <textarea
                  value={item.desc}
                  onChange={(e) => handleTextareaChange(e, item)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-green-500"
                  rows="2"
                />
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-md">
                <select
                  value={item.approve2 || ""}
                  onChange={(e) => handleApprovalChange(e, item)}
                  className={`px-3 py-1 rounded-full ${
                    item.approve2 === "APPROVED"
                      ? "bg-green-100 text-green-700"
                      : item.approve2 === "REJECTED"
                      ? "bg-red-100 text-red-700"
                      : "bg-gray-100 text-gray-700"
                  }`}
                >
                  <option value="" disabled>
                    Select the Status
                  </option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="REJECTED">REJECTED</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Approve Gate Pass Popup */}

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            {/* included create gatepass here */}
            <Approve_Gate_pass_popup
              togglePopup={togglePopup}
              itemData={selectedItem}
            />
          </div>
        </div>
      )}

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Approval</h2>
            <p>Are you sure you want to {selectedStatus} this?</p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className={`px-4 py-2 text-white rounded-xl ${
                  selectedStatus === "APPROVED" ? "bg-green-500" : "bg-red-500"
                }`}
                onClick={updateApprovalStatus}
              >
                Yes, {selectedStatus}
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseSaveConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Approve_02_gate_pass_table;
