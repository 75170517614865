/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoAdd } from "react-icons/io5";
import Gate_pass_table from "./gate_pass_table";
import Gate_pass_popup from "./add_gate_pass_popup";

import Guard_room_form from "./guard_room_form";

function Gate_Pass_Requests() {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [isOpen_2, setIsOpen_2] = useState(false);

  const currentDate = moment().format("MMMM Do YYYY");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const togglePopup_2 = () => {
    setIsOpen_2(!isOpen_2); // Toggle the isOpen_2 state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">Gate Pass Requests</p>
            <div className="flex align-left items-center gap-6">
              <p className="text-[15px] font-semibold text-black ">
                Vehicle Fleet Management System
              </p>
              <p className="text-[15px] font-semibold text-black">
                Route Planning and Optimization
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Gate Pass Requests
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Create a Gate Pass Request
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Gate_pass_table />
      </div>

      <div className="mt-5">
        <div>
          {/* <button
            className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
            onClick={togglePopup_2}
          >
            <IoAdd /> Guard Room Form
          </button> */}
        </div>
      </div>

      {/* Add Driver Process Popup */}

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            {/* included create gatepass here */}
            <Gate_pass_popup togglePopup={togglePopup} />
          </div>
        </div>
      )}

      {/* Add Guard Room Form Popup */}
      {isOpen_2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Guard_room_form togglePopup_2={togglePopup_2} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Gate_Pass_Requests;
