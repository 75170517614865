import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoAdd } from "react-icons/io5";

import VehiclePatTable from "../../components/table/vehicle_parts_table";
import Navbar from "../../components/navbar/navbar";

function Vehicle_part_details() {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const currentDate = moment().format("MMMM Do YYYY");

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="mx-10 mt-5">
      {/* <TbLayoutNavbar /> */}
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Vehicle Information Management
            </p>
            <p className="text-[15px] font-semibold text-[#ABDCB0]">
              Vehicle Parts Details
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Add Vehicle
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <VehiclePatTable />
      </div>

      {/* Add Driver Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddDriverPopup togglePopup={togglePopup} />
          </div>
        </div>
      )} */}
    </div>
  );
}

export default Vehicle_part_details;
