import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import Manager_Transportation_Table from "./manager_transportation_table";
import Add_Manager_Transportation_Schedules from "./add_Manager_Transportation_Schedule";
import Navbar from "../../../components/navbar/navbar";

const Manager_transportation_details = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]); // State to hold fetched data

  const currentDate = moment().format("MMMM Do YYYY");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       setCurrentTime(moment().format("h:mm:ss a"));
  //     }, 1000);
  //     return () => clearInterval(timer);
  //   }, []);

  const exportToCSV = () => {
    // const csvData = employeeData.map((employee) => ({
    //   ID: employee.employee_no,
    //   FullName: employee.employee_fullname,
    //   Department: employee.department_name,
    //   remaining_leave: employee.remaining_leave,
    // }));
    // const csv = Papa.unparse(csvData);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "employee_data.csv");
  };
  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Route Planning and Optimization
            </p>
            <p className="text-[15px] font-semibold text-[#ABDCB0]">
              Manager Transportation Schedule
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Add Manager Transportation Schedule
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Manager_Transportation_Table />
      </div>
      {/* Add Driver Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Add_Manager_Transportation_Schedules togglePopup={togglePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Manager_transportation_details;
